import { useNotification } from 'components/Notification/hooks';
import constants from 'customer/components/emoney/Address/constants';
import { useCallback, useEffect, useState } from 'react';
import { Chain } from 'types/emoney/Chain/indexV2';
import {
  UserRejectedRequestError,
  isErc6492Signature,
  parseErc6492Signature,
} from 'viem';
import {
  useSignMessage as useWagmiSignMessage,
  useSwitchChain as useWagmiSwitchChain,
} from 'wagmi';

export const useEvmSignMessage = (chain: Chain) => {
  const { notifyError, notifyInfo } = useNotification();

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | undefined>(undefined);

  const { signMessageAsync: signEvmMessage } = useWagmiSignMessage();
  const { switchChainAsync: switchEvmChain } = useWagmiSwitchChain();

  // Reset signature state on mount.
  useEffect(() => {
    setSignature(undefined);
  }, []);

  const parseErc6492 = useCallback(
    (hash: `0x${string}` | string) => {
      if (hash && isErc6492Signature(hash as `0x${string}`)) {
        hash = parseErc6492Signature(hash as `0x${string}`).signature;
      }

      return hash;
    },
    [signature],
  );

  /**
   * Only Safe off-chain signing works.
   */

  const signMessage = async (message: string) => {
    setError(undefined);
    setIsLoading(true);
    notifyInfo(constants.SIGNATURE_PROMPT);

    try {
      // Attempt switching chains.
      const chainId = chain?.chainId as number;
      (async () => {
        await switchEvmChain({ chainId: chainId });
      })();
    } catch (e) {
      console.error('Attempting to switch chain' + (e as Error)?.message);
    }

    try {
      const signature = await signEvmMessage({ message: message });

      if (!signature) {
        notifyError('No signature was returned by the wallet.');
        setError('No signature was returned by the wallet.');
        throw Error('No signature was returned by the wallet.');
      }

      setSignature(parseErc6492(signature));
      return parseErc6492(signature);
    } catch (e) {
      let err;
      if (
        (e as Error)?.message?.toLowerCase().includes('no signature') ||
        (e as Error)?.message?.toLowerCase().includes('request expired')
      ) {
        err = 'No signature was returned by the wallet. Please try again.';
      } else if (
        e instanceof UserRejectedRequestError ||
        (e as Error)?.message?.toLowerCase().includes('rejected')
      ) {
        err = 'Signature was rejected.';
      } else {
        err = 'Unable to sign with the wallet because of unknown error.';
      }
      notifyError(err || (e as Error)?.message);
      setError(err || (e as Error)?.message);
      console.error((e as Error)?.message);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    signature: signature,
    signMessage,
    isLoading,
    error,
  };
};
