import { ReactNode } from 'react';

import { useAppContext } from 'customer/context';
import { ChainUniqueName } from 'types/emoney/Chain/indexV2';
import SignatureWizard from './SignatureWizard';

const LinkAccount = ({
  trigger,
  modal = true,
  onSuccess,
}: {
  trigger?: ReactNode;
  modal?: boolean;
  onSuccess?: (address: string, chain?: ChainUniqueName) => void;
}) => {
  const { isFeatureFixChainNames } = useAppContext();
  return (
    <SignatureWizard
      modal={modal}
      trigger={trigger}
      onSuccess={async (signatureData, chainToLink, address) => {
        if (typeof onSuccess === 'function' && address) {
          if (isFeatureFixChainNames) {
            onSuccess(address, chainToLink?.id);
          } else {
            onSuccess(address, chainToLink?.chain);
          }
        }
      }}
    />
  );
};
export default LinkAccount;
