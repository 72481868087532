import restQuery from 'services/rest-query';
import type {
  Account,
  CreateRequest,
  CreateResponse,
  ListRequest,
} from './types';

export default {
  list: ({ profileId, wantBalances }: ListRequest): Promise<Account[]> =>
    restQuery.get(
      `/api/emoney/profiles/${profileId}/accounts?balances=${!!wantBalances}`,
    ),
  create: (payload: CreateRequest): Promise<CreateResponse> =>
    restQuery.post(`/api/addresses`, JSON.stringify(payload)),

  remove: (accountId: string): Promise<Account> =>
    restQuery.patch(`/api/emoney/accounts/${accountId}`, {
      isVisible: false,
    }),
  update: ({ id, ...payload }: Partial<Account>): Promise<Account> =>
    restQuery.patch(`/api/emoney/accounts/${id}`, payload),
  read: (accountId: string): Promise<Account> =>
    restQuery.get(`/api/emoney/accounts/${accountId}`),
};
