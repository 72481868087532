import rest from 'services/rest';
import { PersonKind, RoleArray } from 'types/kyc/Person';
import {
  CorporateProfileV2,
  Details,
  Form,
  Profile,
  ProfileKind,
} from 'types/kyc/Profile';
import { NewVerification, Verification } from 'types/kyc/Verification';
import { ProfilesResponse } from './types';

const list = (): Promise<Profile[]> => rest.get(`/api/iam/profiles`);

const read = (id: string): Promise<Profile> =>
  rest.get(`/api/kyc/profiles/${id}`);

// todo: merge readV2 and readFromAuthProfileId
const readV2 = (id: string): Promise<CorporateProfileV2 | void> =>
  rest.get(`/api/profiles?id=${id}`).then((response) => {
    if ((response as ProfilesResponse).total) {
      return (response as ProfilesResponse).profiles[0];
    }
    return undefined;
  });

const readFromAuthProfileId = (
  id: string,
): Promise<CorporateProfileV2 | void> =>
  rest.get(`/api/profiles?authProfileId=${id}`).then((response) => {
    if ((response as ProfilesResponse).total) {
      return (response as ProfilesResponse).profiles[0];
    }
    return undefined;
  });

/**
 * removePerson removes a person from corporate profile
 * @param {string} profileId Profile ID
 * @param {string} personId Person ID
 */
const removePerson = (profileId: string, personId: string): Promise<Response> =>
  rest.del(`/api/profiles/${profileId}/persons/${personId}`);

const store = (id: string, data: Partial<Profile>): Promise<Profile> =>
  rest.patch(`/api/kyc/profiles/${id}`, data);

const create = (
  kind: ProfileKind,
  authProfileId: string,
): Promise<CorporateProfileV2> =>
  rest.post(`/api/profiles`, { kind, authProfileId });

const storeV2 = (
  id: string,
  data: Partial<CorporateProfileV2>,
): Promise<CorporateProfileV2> => rest.patch(`/api/profiles/${id}`, data);

const storeDetails = (
  profileId: string,
  data: Partial<Details>,
): Promise<Profile> =>
  rest.put(`/api/profiles/${profileId}/details`, { corporate: data });

const storeForm = (profileId: string, data: Partial<Form>): Promise<Profile> =>
  rest.put(`/api/profiles/${profileId}/form`, { corporate: data });

const storeRoles = (profileId: string, data: RoleArray): Promise<Profile> =>
  rest.patch(`/api/profiles/${profileId}/persons`, data);

const removeRole = (
  profileId: string,
  personId: string,
  role: PersonKind,
): Promise<Response> =>
  rest.del(`/api/profiles/${profileId}/persons/${personId}?role=${role}`);

const submit = (profileId: string) =>
  rest.patch(`/api/profiles/${profileId}`, { state: 'submitted' });

const createVerifications = (
  profileId: string,
  data: NewVerification[],
): Promise<Profile> =>
  rest.patch(`/api/profiles/${profileId}/verifications`, {
    verifications: data,
  });

const updateVerifications = (
  profileId: string,
  data: Verification[],
): Promise<Profile> =>
  rest.patch(`/api/profiles/${profileId}/verifications`, {
    verifications: data,
  });

/**
 * createVersion creates a profile version
 * @param {string} id Profile ID
 */
const createVersion = (id: string): Promise<Profile> =>
  rest.post(`/api/kyc/profiles/${id}/versions`);

/**
 * Lists a versions for a profile
 * @param id Profile id
 * @returns list of all versions for this profile
 */
const versions = (id: string): Promise<Profile[]> =>
  rest.get(`/api/kyc/profiles/${id}/versions`);

export default {
  list,
  create,
  read,
  readV2,
  readFromAuthProfileId,
  removePerson,
  store,
  storeV2,
  storeDetails,
  storeForm,
  storeRoles,
  removeRole,
  submit,
  createVerifications,
  updateVerifications,
  createVersion,
  versions,
};
