import { CorporateProfileV2 } from 'types/kyc/Profile';
import { Profile } from './types';

export const isDraft = (profile?: Partial<Profile>) =>
  profile?.meta?.state === 'draft';

export const isPending = (profile?: Partial<Profile>) =>
  profile?.meta?.state === 'submitted' ||
  (profile?.meta?.state === 'pending' && profile?.meta?.outcome === 'none');

export const isApproved = (profile?: Partial<Profile>) =>
  profile?.meta?.outcome === 'approved';

export const isRejected = (profile?: Partial<Profile>) =>
  profile?.meta?.outcome === 'rejected';

export const isBlocked = (profile?: Partial<Profile>) => {
  return profile?.meta?.blocked === true;
};

export const isKindEditable = (profileVersions: Profile[]) =>
  !profileVersions.find(
    (p) => p.meta?.state === 'confirmed' || p.meta?.outcome === 'approved',
  );

export const isDirector = (
  personId: string,
  profile: Partial<CorporateProfileV2>,
) => profile.directors?.find((d) => d.id === personId);

export const isBeneficialOwner = (
  personId: string,
  profile: Partial<CorporateProfileV2>,
) => profile.beneficialOwners?.find((d) => d.id === personId);

export const removeUnwantedAttributes = (
  profile: Partial<CorporateProfileV2>,
): Partial<CorporateProfileV2> => {
  const unwantedKeys: (keyof CorporateProfileV2)[] = ['state'];
  const cleanedProfile: Partial<CorporateProfileV2> = { ...profile };
  for (const key of unwantedKeys) {
    delete cleanedProfile[key];
  }

  return cleanedProfile;
};
