import {
  ReduxStore,
  useAppDispatch,
  useAppSelector,
} from 'customer/store/configureStore';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { PersonKind, RoleArray } from 'types/kyc/Person';
import {
  CorporateProfile,
  CorporateProfileV2,
  Details,
  Form,
  PersonalProfile,
  Profile,
  ProfileKind,
} from 'types/kyc/Profile';
import { NewVerification, Verification } from 'types/kyc/Verification';
import actions from './actions';

interface UseKycProfile {
  profile: PersonalProfile | CorporateProfile | undefined;
  isReadSuccess: boolean;
  create: (
    kind: ProfileKind,
    authProfileId: string,
  ) => Promise<CorporateProfileV2 | void>;
  read: (profileId: string) => Promise<Profile | void>;
  readV2: (profileId: string) => Promise<CorporateProfileV2 | void>;
  readFromAuthProfileId: (
    profileId: string,
  ) => Promise<CorporateProfileV2 | void>;
  reset: (profileId: string, data: Partial<Profile>) => void;
  store: (profileId: string, data: Partial<Profile>) => Promise<Profile | void>;
  storeV2: (
    profileId: string,
    data: Partial<Profile>,
  ) => Promise<Profile | void>;
  storeDetails: (
    profileId: string,
    data: Partial<Details>,
  ) => Promise<CorporateProfileV2 | void>;
  storeForm: (
    profileId: string,
    data: Partial<Form>,
  ) => Promise<CorporateProfileV2 | void>;
  storeRoles: (
    profileId: string,
    data: RoleArray,
  ) => Promise<CorporateProfileV2 | void>;
  removeRole: (
    profileId: string,
    personId: string,
    role: PersonKind,
  ) => Promise<Response | void>;
  createVerifications: (
    profileId: string,
    data: NewVerification[],
  ) => Promise<CorporateProfileV2 | void>;
  updateVerifications: (
    profileId: string,
    data: Verification[],
  ) => Promise<CorporateProfileV2 | void>;
  removePerson: (profileId: string, personId: string) => void;
  versions: (profileId: string) => Promise<Profile[] | void>;
}

const selectProfile = () =>
  useAppSelector((state: ReduxStore) => state.kyc.profile.detail, shallowEqual);

const selectIsReadSuccess = () =>
  useAppSelector(
    (state: ReduxStore) => !!state.kyc.profile.isReadSuccess,
    shallowEqual,
  );

const useKycProfile = (): UseKycProfile => {
  const dispatch = useAppDispatch();

  return {
    profile: selectProfile(),
    isReadSuccess: selectIsReadSuccess(),
    create: useCallback(
      (kind: ProfileKind, authProfileId: string) =>
        dispatch(actions.create(kind, authProfileId)).catch(console.error),
      [dispatch],
    ),
    read: useCallback(
      (profileId: string) =>
        dispatch(actions.read(profileId)).catch(console.error),
      [dispatch],
    ),
    readV2: useCallback(
      (profileId: string) =>
        dispatch(actions.readV2(profileId)).catch(console.error),
      [dispatch],
    ),
    readFromAuthProfileId: useCallback(
      (profileId: string) =>
        dispatch(actions.readFromAuthProfileId(profileId)).catch(console.error),
      [dispatch],
    ),
    reset: useCallback(
      (profileId: string, data: Partial<Profile>) =>
        dispatch(actions.reset(profileId, data)),
      [dispatch],
    ),
    store: useCallback(
      (profileId: string, data: Partial<Profile>) =>
        dispatch(actions.store(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeV2: useCallback(
      (profileId: string, data: Partial<CorporateProfileV2>) =>
        dispatch(actions.storeV2(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeDetails: useCallback(
      (profileId: string, data: Partial<Details>) =>
        dispatch(actions.storeDetails(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeForm: useCallback(
      (profileId: string, data: Partial<Form>) =>
        dispatch(actions.storeForm(profileId, data)).catch(console.error),
      [dispatch],
    ),
    storeRoles: useCallback(
      (profileId: string, data: RoleArray) =>
        dispatch(actions.storeRoles(profileId, data)).catch(console.error),
      [dispatch],
    ),
    removeRole: useCallback(
      (profileId: string, personId: string, role: PersonKind) =>
        dispatch(actions.removeRole(profileId, personId, role)).catch(
          console.error,
        ),
      [dispatch],
    ),
    createVerifications: useCallback(
      (profileId: string, data: NewVerification[]) =>
        dispatch(actions.createVerifications(profileId, data)).catch(
          console.error,
        ),
      [dispatch],
    ),
    updateVerifications: useCallback(
      (profileId: string, data: Verification[]) =>
        dispatch(actions.updateVerifications(profileId, data)).catch(
          console.error,
        ),
      [dispatch],
    ),
    removePerson: useCallback(
      (profileId: string, personId: string) =>
        dispatch(actions.removePerson(profileId, personId)).catch(
          console.error,
        ),
      [dispatch],
    ),
    versions: useCallback(
      (profileId: string) =>
        dispatch(actions.versions(profileId)).catch(console.error),
      [dispatch],
    ),
  };
};

export default useKycProfile;
