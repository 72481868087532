import { useNotification } from 'components/Notification/hooks';
import { useConnected, useSignMessage } from 'components/Wallet/hooks';
import {
  useAccountCreate,
  useAccountList,
  useAccountUpdate,
} from 'customer/components/emoney/Account/hooks';
import RequestSignature from 'customer/components/emoney/Wallet/Wizard/Content/Sign/Request';
import SummaryPanel from 'customer/components/emoney/Wallet/Wizard/Content/Sign/Summary';
import Troubleshoot from 'customer/components/emoney/Wallet/Wizard/Content/Sign/Troubleshoot';
import { useAppContext } from 'customer/context';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { equal } from 'utils/address';
import { WizardContext } from '../../Provider';
import s from './Sign.module.less';

const ProofOwnership = () => {
  useStyles(s);
  const { notifyError } = useNotification();
  const {
    chainToLink,
    walletToLink,
    setChainToLink,
    setWalletToLink,
    setOpen,
    reconnect,
    onSuccess,
    messageToSign,
    setStep,
  } = useContext(WizardContext);

  if (!chainToLink || !walletToLink) {
    console.error('Missing chain or wallet to link');
    console.error('chainToLink:', chainToLink);
    console.error('walletToLink:', walletToLink);
    notifyError('Something went wrong. Reconnecting...');
    setTimeout(() => reconnect(), 2000);
    return null;
  }

  const {
    signMessage,
    signatureData,
    isLoading: isLoadingSignature,
    error: signatureError,
  } = useSignMessage(chainToLink);
  const { address } = useConnected(chainToLink.kind);
  const {
    createAccount,
    isError: isError,
    isPending: isCreatePending,
  } = useAccountCreate();

  const { updateAccount } = useAccountUpdate();
  const { accounts } = useAccountList();
  const { isFeatureFixChainNames } = useAppContext();
  const [isCreateError, setCreateError] = useState(false);

  const handleCreateAccount = useCallback(async () => {
    if (signatureData?.signature) {
      const account = accounts?.length
        ? accounts
            ?.filter(
              (account) =>
                equal(account.address, address) && account.currency === 'eur',
            )
            ?.find((account) =>
              isFeatureFixChainNames
                ? account.chain === chainToLink?.id
                : account.chain === chainToLink?.chain,
            )
        : null;

      const accountIsHidden = account?.isVisible === false;

      try {
        if (accountIsHidden) {
          await updateAccount({
            id: account.id,
            isVisible: true,
          });
        } else {
          await createAccount({
            address: address,
            chain: isFeatureFixChainNames ? chainToLink.id : chainToLink.chain,
            signature: signatureData.signature,
            publicKey: signatureData?.publicKey,
          });
        }
        setOpen(false);
        setChainToLink(undefined);
        setWalletToLink(undefined);
        setStep('CHAIN_SELECTION');
        if (typeof onSuccess === 'function' && address) {
          await onSuccess(signatureData, chainToLink, address);
          return;
        }
      } catch (e) {
        setCreateError(true);
        console.error('Could not link account: ', e);
      }
    }
  }, [signatureData?.signature]);

  useEffect(() => {
    if (isError) {
      setCreateError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (!isCreatePending) {
      handleCreateAccount();
    }
  }, [signatureData?.signature]);

  return (
    <div className={s.wrapper}>
      <SummaryPanel chain={chainToLink} wallet={walletToLink} />
      {!signatureData && !isLoadingSignature && !signatureError && (
        <RequestSignature handleSubmit={() => signMessage(messageToSign)} />
      )}
      {(isLoadingSignature ||
        !!signatureError ||
        isCreatePending ||
        isCreateError) && (
        <Troubleshoot
          messageToSign={messageToSign}
          handleSubmit={() => signMessage(messageToSign)}
          handleReconnect={() => reconnect()}
        />
      )}
    </div>
  );
};
export default ProofOwnership;
