import { Chain } from 'types/emoney/Chain/indexV2';

const chains: Chain[] = [
  {
    id: 'sepolia',
    chain: 'ethereum',
    chainId: 11155111,
    kind: 'evm',
    label: 'Ethereum Sepolia',
    explorerUrl: 'https://sepolia.etherscan.io',
  },
  {
    id: 'amoy',
    chain: 'polygon',
    chainId: 80002,
    kind: 'evm',
    label: 'Polygon Amoy',
    explorerUrl: 'https://amoy.polygonscan.com',
  },
  {
    id: 'chiado',
    chain: 'gnosis',
    chainId: 10200,
    kind: 'evm',
    label: 'Gnosis Chiado',
    explorerUrl: 'https://blockscout.chiadochain.net',
  },
  {
    id: 'arbitrumsepolia',
    chain: 'arbitrum',
    chainId: 421614,
    kind: 'evm',
    label: 'Arbitrum Sepolia',
    explorerUrl: 'https://sepolia.arbiscan.io',
  },
  {
    id: 'lineasepolia',
    chain: 'linea',
    chainId: 59141,
    kind: 'evm',
    label: 'Linea Sepolia',
    explorerUrl: 'https://sepolia.lineascan.build',
  },
  {
    id: 'florinlocal',
    chain: 'noble',
    chainId: 'florin-1',
    kind: 'cosmos',
    label: 'Noble Florin local',
    explorerUrl: 'http://localhost:1317',
  },
  {
    id: 'florin',
    chain: 'noble',
    chainId: 'florin-devnet-1',
    kind: 'cosmos',
    label: 'Noble Florin devnet',
    explorerUrl: 'https://explorer.florin.noble.xyz/florin',
  },
  {
    id: 'grand',
    chain: 'noble',
    chainId: 'grand-1',
    kind: 'cosmos',
    label: 'Noble Grand',
    explorerUrl: 'https://www.mintscan.io/noble-testnet',
  },
  {
    id: 'noble',
    chain: 'noble',
    chainId: 'noble-1',
    kind: 'cosmos',
    label: 'Noble',
    explorerUrl: 'https://www.mintscan.io/noble',
  },

  {
    id: 'gnosis',
    chain: 'gnosis',
    chainId: 100,
    kind: 'evm',
    label: 'Gnosis',
    explorerUrl: 'https://gnosisscan.io',
  },
  {
    id: 'ethereum',
    chain: 'ethereum',
    chainId: 1,
    kind: 'evm',
    label: 'Ethereum',
    explorerUrl: 'https://etherscan.io',
  },
  {
    id: 'polygon',
    chain: 'polygon',
    chainId: 137,
    kind: 'evm',
    label: 'Polygon',
    explorerUrl: 'https://polygonscan.com',
  },
  {
    id: 'arbitrum',
    chain: 'arbitrum',
    chainId: 42161,
    kind: 'evm',
    label: 'Arbitrum',
    explorerUrl: 'https://arbiscan.io',
  },
  {
    id: 'linea',
    chain: 'linea',
    chainId: 59144,
    kind: 'evm',
    label: 'Linea',
    explorerUrl: 'https://lineascan.build',
  },
  {
    id: 'columbus',
    chain: 'camino',
    chainId: 501,
    kind: 'evm',
    label: 'Camino Columbus',
    explorerUrl: 'https://suite.camino.network/explorer/columbus/c-chain',
  },
  {
    id: 'camino',
    chain: 'camino',
    chainId: 500,
    kind: 'evm',
    label: 'Camino',
    explorerUrl: 'https://suite.camino.network/explorer/camino/c-chain',
  },
  {
    id: 'ethereumlocal',
    chain: 'ethereum',
    chainId: 6666,
    kind: 'evm',
    label: 'Ethereum Local',
    explorerUrl: 'http://localhost:8545',
  },
];
export default chains;
