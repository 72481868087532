import { useQuery } from '@tanstack/react-query';
import { keyPrefix } from 'providers/QueryProvider';
import { ChainUniqueName } from 'types/emoney/Chain/indexV2';
import { CurrencyCode, Token } from 'types/emoney/Token';
import { equal } from 'utils/address';
import { getTokens as get } from './service';

const useTokens = () => {
  return useQuery<Token[]>({
    queryKey: keyPrefix(['emoney-tokens-list']),
    queryFn: get,
    staleTime: Infinity,
    retry: false,
  });
};

/**
 * Filter down to a single token based on currency and chain
 */
const useToken = (currency?: CurrencyCode, chain?: ChainUniqueName) => {
  const tokensQuery = useTokens();

  return useQuery<Token>({
    queryKey: keyPrefix(['emoney-token', { currency, chain }]),
    queryFn: () => {
      if (!tokensQuery.data) {
        throw new Error('Tokens data is not available');
      }
      const token = tokensQuery.data.find(
        (token) => equal(token.currency, currency) && equal(token.chain, chain),
      );
      if (!token) {
        throw new Error('Token not found');
      }

      return token;
    },
    enabled: tokensQuery.isSuccess && !!currency && !!chain, // Only run when both are defined and tokensQuery is successful
    staleTime: Infinity,
  });
};

export { useTokens, useToken };
