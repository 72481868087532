import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'components/Notification/hooks';
import { getKeys } from 'customer/cacheKeys';
import useProfile from 'customer/components/iam/Profile/hooks';
import { Account } from 'types/emoney/Account';
import services from './serviceV2';

export const useAccountList = ({
  wantBalances = true,
}: { wantBalances?: boolean } = {}) => {
  const { profileId } = useProfile();
  // Query 1: Fetch accounts without balances
  const {
    data: withoutBalances,
    isPending: isPendingWithoutBalances,
    isSuccess: isSuccessWithoutBalances,
    isFetching: isFetchingWithoutBalances,
    error: withoutBalancesError,
    refetch,
  } = useQuery<Account[]>({
    queryKey: getKeys.accountListWithoutBalances,
    queryFn: async () =>
      services.list({ profileId, wantBalances: false }) as Promise<Account[]>,
    enabled: !!profileId,
    staleTime: 1000 * 60 * 5, // Stale after 5 minutes
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  // Query 2: Fetch accounts with balances, running simultaneously
  const {
    data: withBalances,
    isPending: isPendingWithBalances,
    isSuccess: isSuccessWithBalances,
    isFetching: isFetchingWithBalances,
    error: withBalancesError,
  } = useQuery<Account[]>({
    queryKey: getKeys.accountListBalances,
    queryFn: async () =>
      services.list({ profileId, wantBalances: true }) as Promise<Account[]>,
    enabled: !!profileId && !!wantBalances,
    staleTime: 1000 * 60 * 1, // Stale after 1 minutes
    refetchOnMount: true,
    refetchOnReconnect: true,
    // gcTime: 1000 * 60 * 5, // Garbage collect after 5 minutes
  });

  return {
    // If accounts with balances are available, use them, otherwise fall back to accounts without balances
    accounts: withBalances || withoutBalances,
    // Show loading only if both queries are loading
    isPending: isPendingWithoutBalances || isPendingWithBalances,
    isSuccess: isSuccessWithoutBalances || isSuccessWithBalances,
    isFetching: isFetchingWithoutBalances || isFetchingWithBalances,
    error: withoutBalancesError || withBalancesError,
    refetch,
  };
};

/*
 * Read a single account.
 */
export const useAccountRead = (accountId?: string) => {
  const { data, isPending, isSuccess, error, isFetched, isFetching, isError } =
    useQuery<Account>({
      enabled: !!accountId,
      queryKey: getKeys.accountRead(accountId as string),
      queryFn: () => services.read(accountId as string) as Promise<Account>,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 1000 * 60 * 5, // Stale after 5 minutes
      retry: 1,
    });

  return {
    account: data,
    isFetched,
    isFetching,
    isPending,
    isSuccess,
    isError,
    error,
  };
};

export const useAccountRemove = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: getKeys.accountRemove,
    mutationFn: (accountId: string) => services.remove(accountId),
    onSuccess() {
      // Refetch all accounts on success.
      queryClient.invalidateQueries({
        queryKey: getKeys.accountListBalances,
      });
    },
    // onError not needed,
    // there is a global catch and notify errors
    // see setMutationDefaults in ReactQueryProvider
  });
  return {
    removeAccount: mutateAsync,
    isPending,
    isError,
    error,
  };
};

export const useAccountCreate = () => {
  const queryClient = useQueryClient();
  const { notifyInfo } = useNotification();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: getKeys.accountCreate,
    mutationFn: services.create,
    retry: 0,
    onSuccess(data) {
      if (data && data?.state === 'pending') {
        notifyInfo(
          'Transaction awaiting signatures. Please ensure all required parties sign.',
        );
        // This is a smart contract. Let's invalidate the pending signatures.
        // TODO: pending signatures for linked accounts is not implemented yet.
        // queryClient.invalidateQueries({ queryKey: pendingSignatureKey(data.address) });
      } else {
        // Refetch all accounts on success.
        queryClient.invalidateQueries({
          queryKey: getKeys.accountListBalances,
        });
      }
    },
    // onError not needed,
    // there is a global catch and notify errors
    // see setMutationDefaults in ReactQueryProvider
  });

  return {
    createAccount: mutateAsync,
    isPending,
    isError,
    error,
  };
};

export const useAccountUpdate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, isSuccess, error } = useMutation({
    mutationKey: getKeys.accountUpdate,
    mutationFn: services.update,
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: getKeys.accountRead(data.id),
      });
      // Refetch all accounts on success.
      await queryClient.invalidateQueries({
        queryKey: getKeys.accountListBalances,
      });
    },
    // onError not needed,
    // there is a global catch and notify errors
    // see setMutationDefaults in ReactQueryProvider
  });

  return {
    updateAccount: mutateAsync,
    isPending,
    isError,
    isSuccess,
    error,
  };
};
