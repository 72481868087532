import TokenIcon from 'components/Icons/TokenIcon';
import { useTokens } from 'components/Token/hooks';
import { useConnected } from 'components/Wallet/hooks';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { useState } from 'react';
import { CurrencyCode } from 'types/emoney/Token';
import { equal } from 'utils/address';
import { chainSortOrder } from 'utils/emoney/chains';
import cu from '../../Token/util';
import AccountCard from '../Card/Card';
import { useSettingsContext } from '../Settings/SettingsProvider';
import { Account, AccountByCurrency } from '../types';
import s from './Group.less';

const CurrencyGroup = ({
  accountsByCurrency,
  sumByCurrency,
}: {
  accountsByCurrency: AccountByCurrency;
  sumByCurrency?: Record<CurrencyCode, number>;
}) => {
  useStyles(s);
  useState<Record<CurrencyCode, number>>();
  const { address: connectedAddressCosmos, chain: connectedChainCosmos } =
    useConnected('cosmos');
  const { address: connectedAddressEvm, chain: connectedChainEvm } =
    useConnected('evm');

  const { settingsView } = useSettingsContext();
  const { data: tokens } = useTokens();

  const isMatch = (x: Account) => {
    return (
      (equal(x.address, connectedAddressCosmos) &&
        x.chain === connectedChainCosmos?.chain) ||
      (equal(x.address, connectedAddressEvm) &&
        x.chain === connectedChainEvm?.chain)
    );
  };

  const isAddressMatch = (a: Account, b: Account) => {
    return (
      (equal(a.address, connectedAddressCosmos) &&
        !equal(b.address, connectedAddressCosmos)) ||
      (equal(a.address, connectedAddressEvm) &&
        !equal(b.address, connectedAddressEvm))
    );
  };

  const sortByBalanceChainAddress = (a: Account, b: Account) => {
    const balanceA = a.balance ? parseFloat(a.balance) : -Infinity;
    const balanceB = b.balance ? parseFloat(b.balance) : -Infinity;

    // At the top of the list, show the connected account.
    if (isMatch(a)) {
      return -1;
    }
    if (isMatch(b)) {
      return 1;
    }

    if (balanceA !== balanceB) {
      return balanceB - balanceA;
    }
    if (isAddressMatch(a, b)) {
      return -1;
    }
    if (isAddressMatch(b, a)) {
      return 1;
    }
    if (a.chain && b.chain) {
      const chainComparison =
        chainSortOrder.indexOf(a.chain) - chainSortOrder.indexOf(b.chain);
      if (chainComparison !== 0) {
        return chainComparison;
      }
    }
    return a.address.localeCompare(b.address);
  };

  /** Leave the connected account in the first slot and sort rest by balance. */
  const filteredAccounts = {
    ...accountsByCurrency,
    accounts: accountsByCurrency.accounts.sort(sortByBalanceChainAddress),
  };

  const filterAccounts = (account: Account) => {
    return account?.isVisible || settingsView;
  };

  const showCurrencySection = (currency: CurrencyCode) => {
    const balance = sumByCurrency ? sumByCurrency[currency] : undefined;
    return balance !== undefined || settingsView;
  };

  const renderCurrencyBalance = (currency: CurrencyCode) => {
    const cFormat = cu.formatter(currency);
    const token = tokens?.find((t) => t.currency === currency);
    const balance = sumByCurrency && sumByCurrency[currency];

    return (
      <div key={`currency-sum-${currency}`} className={s.balance}>
        <TokenIcon className={s.icon} currencyCode={currency} />
        {balance !== undefined && (
          <div className={s.header}>
            <small>TOTAL</small>
            <span>
              {cFormat.format(balance as number)} {token?.symbol}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div key={`currency-section-${filteredAccounts.currency}`}>
      {showCurrencySection(filteredAccounts.currency) && (
        <div className={s.sectionWrapper}>
          {renderCurrencyBalance(filteredAccounts.currency)}
          {filteredAccounts.accounts.map((account) => (
            <div key={`account-list-${account.id}`}>
              {filterAccounts(account) && (
                <div className={s.cardWrapper}>
                  {isMatch(account) && (
                    <div
                      title="Connected wallet"
                      className={s.connectedIndicator}
                    />
                  )}
                  <AccountCard account={account} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CurrencyGroup;
