export const LIST_REQUEST = 'KYC_PROFILE_LIST_REQUEST';
export const LIST_SUCCESS = 'KYC_PROFILE_LIST_SUCCESS';
export const LIST_FAILURE = 'KYC_PROFILE_LIST_FAILURE';
export const READ_REQUEST = 'KYC_PROFILE_READ_REQUEST';
export const READ_SUCCESS = 'KYC_PROFILE_READ_SUCCESS';
export const READ_FAILURE = 'KYC_PROFILE_READ_FAILURE';
export const STORE_REQUEST = 'KYC_PROFILE_STORE_REQUEST';
export const STORE_SUCCESS = 'KYC_PROFILE_STORE_SUCCESS';
export const STORE_FAILURE = 'KYC_PROFILE_STORE_FAILURE';
export const STORE_DETAILS_FAILURE = 'KYC_PROFILE_STORE_DETAILS_FAILURE';
export const STORE_DETAILS_REQUEST = 'KYC_PROFILE_STORE_DETAILS_REQUEST';
export const STORE_DETAILS_SUCCESS = 'KYC_PROFILE_STORE_DETAILS_SUCCESS';
export const STORE_FORM_FAILURE = 'KYC_PROFILE_STORE_FORM_FAILURE';
export const STORE_FORM_REQUEST = 'KYC_PROFILE_STORE_FORM_REQUEST';
export const STORE_FORM_SUCCESS = 'KYC_PROFILE_STORE_FORM_SUCCESS';
export const STORE_ROLES_FAILURE = 'KYC_PROFILE_STORE_ROLES_FAILURE';
export const STORE_ROLES_REQUEST = 'KYC_PROFILE_STORE_ROLES_REQUEST';
export const STORE_ROLES_SUCCESS = 'KYC_PROFILE_STORE_ROLES_SUCCESS';
export const STORE_VERIFICATIONS_FAILURE =
  'KYC_PROFILE_STORE_VERIFICATIONS_FAILURE';
export const STORE_VERIFICATIONS_REQUEST =
  'KYC_PROFILE_STORE_VERIFICATIONS_REQUEST';
export const STORE_VERIFICATIONS_SUCCESS =
  'KYC_PROFILE_STORE_VERIFICATIONS_SUCCESS';
export const CREATE_VERSION_REQUEST = 'KYC_PROFILE_CREATE_VERSION_REQUEST';
export const CREATE_VERSION_SUCCESS = 'KYC_PROFILE_CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAILURE = 'KYC_PROFILE_CREATE_VERSION_FAILURE';
export const VERSIONS_FAILURE = 'KYC_VERSIONS_FAILURE';
export const VERSIONS_REQUEST = 'KYC_VERSIONS_REQUEST';
export const VERSIONS_SUCCESS = 'KYC_VERSIONS_SUCCESS';
export const REMOVE_PERSON_FAILURE = 'KYC_REMOVE_PERSON_FAILURE';
export const REMOVE_PERSON_REQUEST = 'KYC_REMOVE_PERSON_REQUEST';
export const REMOVE_PERSON_SUCCESS = 'KYC_REMOVE_PERSON_SUCCESS';
export const RESET_FAILURE = 'KYC_RESET_FAILURE';
export const RESET_REQUEST = 'KYC_RESET_REQUEST';
export const RESET_SUCCESS = 'KYC_RESET_SUCCESS';
export const PROFILE_KIND_PERSONAL = 'personal';
export const PROFILE_KIND_CORPORATE = 'corporate';

export default {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  STORE_REQUEST,
  STORE_SUCCESS,
  STORE_FAILURE,
  STORE_DETAILS_FAILURE,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_SUCCESS,
  STORE_FORM_FAILURE,
  STORE_FORM_REQUEST,
  STORE_FORM_SUCCESS,
  STORE_ROLES_FAILURE,
  STORE_ROLES_REQUEST,
  STORE_ROLES_SUCCESS,
  STORE_VERIFICATIONS_FAILURE,
  STORE_VERIFICATIONS_REQUEST,
  STORE_VERIFICATIONS_SUCCESS,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  CREATE_VERSION_FAILURE,
  VERSIONS_FAILURE,
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
  REMOVE_PERSON_FAILURE,
  REMOVE_PERSON_REQUEST,
  REMOVE_PERSON_SUCCESS,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_FAILURE,
  PROFILE_KIND_PERSONAL,
  PROFILE_KIND_CORPORATE,
};
